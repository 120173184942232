 // 防止处理多次点击
 function noMultipleClicks(methods, info) {
    // methods是需要点击后需要执行的函数， info是点击需要传的参数
    let that = this;
   if (that.noClick) {
        that.noClick= false;
        if(info && info !== '') {
           methods(info);
       } else {
           methods();
       }
       setTimeout(()=> {
           that.noClick= true;
       }, 2000)
   } else {
   }
}
//导出
export default {
   noMultipleClicks,//禁止多次点击
}