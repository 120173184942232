// 处理imgurl
function urlFilter(value) {
    if (!value) {
        return require('@/assets/img/header.png')
    }
    let url = String(value)
    let i = url.indexOf('/upload')
    if (i > -1) {
        return process.env.VUE_APP_API_URL + url.substring(i + 1)
    } else {
        return value
    }
}
export default { urlFilter }