import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueRouter from 'vue-router'
import ElementUI from 'element-ui'
import Vuex from 'vuex'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/font/font.css'
import { apiGet, apiPost } from './utils/request'
import dragVerify from 'vue-drag-verify2'
import { VueJsonp } from 'vue-jsonp'
import store from './vuex/store'
import common from './common/common'
import i18n from './assets/i18n/index'
import url from './common/url'
import filters from "./common/filter"
import Base64 from './common/base64'

Vue.use(VueRouter)
Vue.use(Vuex)
Vue.use(ElementUI)
Vue.use(dragVerify)
Vue.use(VueJsonp)

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});

Vue.prototype.$apiGet = apiGet
Vue.prototype.$apiPost = apiPost
Vue.prototype.$store = store
Vue.prototype.$noMultipleClicks = common.noMultipleClicks;
Vue.prototype.$url = url
Vue.prototype.$Base64 = Base64;

Vue.config.productionTip = false
// console.log("url:", process.env.VUE_APP_API_URL)
new Vue({
  render: h => h(App),
  router,
  store,
  i18n
}).$mount('#app')
