import { createStore } from 'vuex'
const store = createStore({
  state: {
    user: localStorage.getItem('user') ? localStorage.getItem('user') : '',
    refreshFlag: false,
    platformChange: false,
    roleChange: false
  },
  getters: {
    currentUser: state => state.user,
  },
  mutations: {
    setUser(state, user) {
      state.user = user
      localStorage.setItem('user', JSON.stringify(user));
    },
    toggleRefreshFlag: (state) => {
      state.refreshFlag = !state.refreshFlag
    },
    togglePlatform: (state) => {
      state.platformChange = !state.platformChange
    },
    toggleRole: (state) => {
      state.roleChange = !state.roleChange
    }
  }
})
export default store