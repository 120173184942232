export default {
    login: {
        tab1: "账号登录",
        tab2: "手机登录"
    },
    input: {
        i1: "请输入账号",
        i2: "请输入密码",
        i3: "请输入手机号",
        i4: "请输入验证码",
        i5: "请输入用户名/手机号",
        i6: "请输入关键词",
        i7: "请输入",
        i8: "请输入姓名",
        i9: "请输入用户角色标签"
    },
    button: {
        b1: "登录",
        b2: "忘记密码",
        b3: "免费注册",
        b4: "使用微信登录",
        b5: "获取验证码",
        b6: "已发送",
        b7: "确 定",
        b8: "取 消",
        b9: "重新获取",
        b10: "添加",
        b11: "管理"
    },
    select: {
        s1: "请选择用户状态",
        s2: "请选择"
    },
    logOut: "成功退出，两秒后返回首页",
    console: "控制台",
    yousu: "悠速",
    home: "首页",
    user: "用户",
    addUser: "新增用户",
    editUser: '编辑用户',
    platform: "平台",
    setting: "设置",
    userList: "用户列表",
    agencyList: "代理列表",
    role: "角色",
    addRole: "用户角色标签",
    roleList: "角色列表",
    groupList: "小组列表",
    platformList: "平台列表",
    trySetting: "试用设置",
    account: "账号",
    password: "密码",
    accountSetting: "账号设置",
    smartSetting: "智能设置",
    agencySetting: "代理设置",
    agreementSetting: "协议设置",
    reminderSetting: "到期提醒设置",
    reminderTime: "到期提醒",
    reminderText: "尊敬的客户，您的CRM系统已到期，如需继续使用请及时续费或者来电咨询。",
    openUp: "开通",
    expired: "过期",
    haveOpened: "已开通",
    haveExpired: "已过期",
    haveNotOpened: "未开通",
    homePage: {
        h1: "产品展示",
        h2: "已开通的产品",
        h3: "推荐产品",
        h4: "可代理的产品",
        h5: "已过期的产品",
        s1: "立即进入",
        s2: "我要代理",
        s3: "立即续费",
        s4: "点击开通",
        b1: "我的账号",
        b2: "退出系统"
    },
    userListPage: {
        u1: "总用户量",
        u2: "今日新增用户量",
        u3: "昨日新增用户量",
    },
    agencyListPage: {
        a1: "总代理量",
        a2: "今日新增代理量",
        a3: "昨日新增代理量"
    },
    platformPage: {
        p1: "首页是否展示"
    },
    platformsNum: "总平台数",
    platformName: "平台名称",
    platformSort: "平台排序",
    platformInfo: "平台信息",
    username: "用户名",
    tel: "手机号",
    userstatus: "用户状态",
    find: "查询",
    normal: "正常",
    disable: "禁用",
    open: "开启",
    close: "关闭",
    userinfo: "用户信息",
    compellation: "姓名",
    address: "住址",
    type: "类型",
    handle: "操作",
    edit: "编辑",
    delete: "删除",
    look: "查看",
    userDetails: "用户详情",
    person: "个人",
    company: "公司",
    enterprise: "企业",
    joinDate: "注册时间",
    dueTime: "试用期到期时间",
    userId: "用户id",
    userInfoPage: {
        b1: "进入crm系统查看详情",
        b2: "用户区域"
    },
    personUser: "个人用户",
    companyUser: "企业用户",
    Popconfirm: {
        del: "确定删除此用户？",
        del1: "确定删除此角色？"
    },
    succeed: "成功",
    companyName: "公司名称",
    expiryDate: "有效日期",
    permissions: "用户权限",
    submit: "提交",
    addTime: "添加时间",
    subTime: "提交时间",
    default: "默认",
    system: {
        s1: "crm系统",
        s2: "一键发布系统"
    },
    fewer: "收起",
    unfold: "展开",
    add: "新增",
    agentSystem: "代理系统",
    agencyArea: "代理区域",
    status: "状态",
    attn: "联系人",
    contactNumber: "联系电话",
    pay: "付费",
    paySetting: "付费设置",
    personEdition: "个人版",
    companyEdition: "企业版",
    paymentPeriod: "付费期限",
    price: "价格",
    population: "人数",
    label: "标签",
    IncreaseAmount: "增加金额",
    permanent: "永久",
    month: "月",
    yuan: "元",
    man: "人",
    tian: "天",
    payPage: {
        p1: "添加套餐，最多添加3个",
        p2: "注：套餐增加一人需要增加的金额"
    },
    platformInfoPage: {
        p1: "排序",
        p2: "平台logo",
        p3: "联系人姓名",
        p4: "微信号",
        p5: "是否推荐",
        p6: "是否展示"
    },
    wx: "微信",
    zfb: "支付宝",
    save: "保 存",
    cancel: "取 消",
    message: {
        m1: "图片上传成功",
        m2: "修改成功",
        m3: "失败",
        m4: "数据获取失败"
    },
    trySettingPage: {
        t1: "默认试用时间",
        t2: "请输入试用天数",
        t3: "默认试用权限：",
    },
    smartSettingPage: {
        s1: "是否开启智能推荐"
    },
    serviceAgreement: "服务协议",
    accountSettingPage: {
        a1: "修改头像",
        a2: "账户名称",
        a3: "账户密码",
        a4: "手机号码"
    },
    verification: "验证手机",
    update: "修改",
    updateOk: "修改成功",
    verificationPage: {
        v1: "手机验证码验证：账号",
        v2: "为确定你本人操作，请完成以下验证",
        v3: "短信验证码"
    },
    next: "下一步",
    updatePage: {
        u1: "修改登录密码",
        u2: "绑定新手机号",
        u3: "旧密码",
        u4: "新密码",
        u5: "确认密码",
        u6: "请输入旧密码",
        u7: "请输入新密码",
        u8: "请确认密码"
    },
    confirm: "确定",
    updateOkPage: {
        u1: "修改成功，你可以使用新密码登录本平台",
        u2: "修改成功，你可以使用新手机号登录本平台",
        u3: "重新登陆"
    },
    header: "头像",
    clickUpload: "点击上传",
    assignRoles: "分配角色",
    wantAgency: "代理信息填写",
    wantAgencyPage: {
        w1: "代理详情，请联系工作人员",
    },
    rolePage: {
        r1: "已关联成员数",
        r2: "请输入角色名称"
    },
    group: "小组",
    addGroup: "新增小组",
    editGroup: "编辑小组",
    groupPage: {
        g1: "部门名称",
        g2: "请输入小组名称"
    },
    bound: "已绑定",
    openSystem: "系统开通",
    openSystemPage: {
        o2: "方案详情",
        o3: "系统名称",
        o4: "购买时长",
        o5: "到期时间",
        o6: "订单总额",
        o7: "选择以下支付方式",
        o8: "微信支付",
        o9: "支付宝支付",
        o10: "去支付",
        o11: "使用人数",
        o12: "原套餐",
        o13: "增加人数金额",
    },
    addUserPage: {
        a1: "所属部门",
        a2: "所属角色",
        a3: "所属平台",
        a4: "所属身份",
        a5: "为该小组负责人，是否更换？",
        a6: "为该公司负责人，是否更换？"
    },
    upgrade: "升级企业版",
    upgradePage: {
        u1: "系统开通三个月内，升级企业账号联系商务可享差价优惠",
        u2: "请输入公司信息",
        u3: "企业机构代码",
        u4: "目前您的账号为个人账号，只能购买部分系统；",
        u5: "填写公司名称后可升级为企业账号购卖全部系统；"
    },
    replace: "更换",
    payCom: {
        p1: "你购买的",
        p2: "使用权",
        p3: "应付金额",
        p4: "请使用",
        p5: "扫一扫，扫描",
        p6: "二维码支付",
        p7: "支付即视为您同意",
        p8: "服务协议",
        p9: "条例",
        p10: "选择其他支付方式"
    },
    successfullyOpened: "开通成功",
    openingFailure: "开通失败",
    retry: "重试",
    backHomepage: "返回首页",
    serviceTel: "客服电话",
    timeOnline: "在线时间",
    forenoon: "上午",
    afternoon: "下午",
    startTime: "起始时间",
    endTime: "结束时间",
    reminderPange: {
        r1: "全天24小时在线",
        r2: "设置在线时间"
    },
    renew: "续费",
    callOut: "外呼管理",
    softPhone: "软电话管理",
    undistributed: "未分配",
    allocated: "已分配"
}