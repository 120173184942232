export default {
    login: {
        tab1: "账号登录",
        tab2: "手机登录"
    },
    input: {
        i1: "请输入账号",
        i2: "请输入密码",
        i3: "请输入手机号",
        i4: "请输入验证码",
        i5: "请输入用户名/手机号"
    },
    button: {
        b1: "登录",
        b2: "忘记密码",
        b3: "免费注册",
        b4: "使用微信登录",
        b5: "获取验证码",
        b6: "已发送"
    },
    select: {
        s1: "请选择用户状态"
    },
    logOut: "成功退出，两秒后返回首页",
    console: "控制台",
    yousu: "悠速",
    home: "首页",
    user: "用户",
    platform: "平台",
    setting: "设置",
    userList: "用户列表",
    agencyList: "代理列表",
    platformList: "平台列表",
    trySetting: "试用设置",
    accountSetting: "账号设置",
    smartSetting: "智能设置",
    agencySetting: "代理设置",
    agreementSetting: "协议设置",
    homePage: {
        h1: "产品展示"
    },
    userListPage: {
        u1: "总用户量",
        u2: "今日新增用户量",
        u3: "昨日新增用户量",
    },
    username: "用户名",
    tel: "手机号",
    userstatus: "用户状态",
    find: "查询",
    normal: "正常",
    disable: "禁用",
    userinfo: "用户信息",
    compellation: "姓名",
    address: "住址",
    type: "类型",
    handle: "操作",
    edit: "编辑",
    delete: "删除",
    look: "查看",
    userDetails: "用户详情",
    person: "个人",
    company: "公司",
    joinDate: "注册时间",
    dueTime: "试用期到期时间",
    userId: "用户id",
    userInfoPage: {
        b1: "进入crm系统查看详情",
        b2: "用户区域"
    }
}