import axios from 'axios';

const instance = axios.create({
    timeout: 10000,
    baseURL: process.env.VUE_APP_API_URL,
    // baseURL: 'http://10.3.10.79:82/',
    // baseURL: 'https://apiscrm.youqucms.com/',
})
// 添加响应拦截器
instance.interceptors.request.use(
    config => {
        const token = localStorage.getItem('token')
        if (token) {
            config.headers['user-token'] = token;
        }
        return config;
    }, error => {
        // console.log(err.response.status,999)
        return Promise.reject(error);
    });


export function apiGet(url, params) {
    return new Promise((resolve, reject) => {
        instance.get(url, {
            params: params,
        }).then(res => {
            resolve(res.data);
        }).catch(err => {
            reject(err.data)
        })
    });
}



export function apiPost(url, params) {
    return new Promise((resolve, reject) => {
        instance({
            method: 'post',
            url: url,
            data: params
        }).then(res => {
            resolve(res.data);
        }).catch(err => { reject(err.data) })
    });
}

