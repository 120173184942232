import Vue from 'vue';
import VueRouter from 'vue-router';
import i18n from '../assets/i18n/index'
Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, resolve, reject) {
    if (resolve || reject) return originalPush.call(this, location, resolve, reject)
    return originalPush.call(this, location).catch((e) => { })
}
const scrollBehavior = function scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
        return savedPosition;
    } else {
        return { x: 0, y: 0 }
    }
};

const router = new VueRouter({
    // mode: 'history',
    base: 'scrm',
    routes: [
        {
            path: '/',
            component: () => import('../view/Login'),
            meta: {
                isAuth: true
            }
        },
        {
            path: '/register',
            component: () => import('../view/Register'),
        },
        {
            path: '/repassword',
            component: () => import('../view/rePassword'),
            children: [
                {
                    path: '/', redirect: 'input'
                },
                {
                    path: 'input',
                    component: () => import('../view/component/rePassword/inputMessage')
                },
                {
                    path: 'verifyCode',
                    component: () => import('../view/component/rePassword/verifyCode')
                },
                {
                    path: 'anew',
                    component: () => import('../view/component/rePassword/anew')
                },
                {
                    path: 'success',
                    component: () => import('../view/component/rePassword/success')
                }
            ]
        },
        {
            path: '/wxlogin',
            component: () => import('../view/wxlogin'),
        },
        {
            path: '/home',
            component: () => import('../view/home'),
            children: [
                {
                    path: '/',
                    component: () => import('../view/component/home/index'),
                    meta: {
                        isAuth: true,
                        title: 'home',
                        keepAlive: true
                    }
                },
                {
                    path: 'userlist',
                    component: () => import('../view/component/user/userList'),
                    meta: {
                        isAuth: true,
                        title: 'userList',
                        keepAlive: true
                    }
                },
                {
                    path: 'addUser',
                    component: () => import('../view/component/user/addUser'),
                    meta: {
                        isAuth: true,
                        title: 'userinfo',
                        keepAlive: false
                    }
                },
                {
                    path: 'rolelist',
                    component: () => import('../view/component/user/roleList'),
                    meta: {
                        isAuth: true,
                        title: 'roleList',
                        keepAlive: true
                    }
                },
                {
                    path: 'addrole',
                    component: () => import('../view/component/user/addRole'),
                    meta: {
                        isAuth: true,
                        title: 'addRole',
                        keepAlive: false
                    }
                },
                {
                    path: 'grouplist',
                    component: () => import('../view/component/user/groupList'),
                    meta: {
                        isAuth: true,
                        title: 'groupList',
                        keepAlive: true
                    }
                },
                {
                    path: 'agencylist',
                    component: () => import('../view/component/user/agencyList'),
                    meta: {
                        isAuth: true,
                        title: 'agencyList',
                        keepAlive: true
                    }
                },
                {
                    path: 'wantagency',
                    component: () => import('../view/component/user/wantAgency'),
                    name: 'wantAgency',
                    meta: {
                        isAuth: true,
                        title: 'wantAgency',
                        keepAlive: true
                    }
                },
                {
                    path: 'userinfo',
                    component: () => import('../view/component/userInfo/userInfo'),
                    meta: {
                        isAuth: true,
                        title: 'userinfo',
                        keepAlive: false
                    }
                },
                {
                    path: 'platformlist',
                    component: () => import('../view/component/platform/platformList'),
                    meta: {
                        isAuth: true,
                        title: 'platformList',
                        keepAlive: true
                    }
                },
                {
                    path: 'platformInfo',
                    component: () => import('../view/component/platform/platformInfo'),
                    meta: {
                        isAuth: true,
                        title: 'platformInfo',
                        keepAlive: false
                    }
                },
                {
                    path: 'opensystem',
                    component: () => import('../view/component/platform/openSystem'),
                    name: "openSystem",
                    meta: {
                        isAuth: true,
                        title: 'openSystem',
                        keepAlive: true
                    }
                },
                {
                    path: 'enterprise',
                    component: () => import('../view/component/platform/enterprise'),
                    name: "enterprise",
                    meta: {
                        isAuth: true,
                        title: 'enterprise',
                        keepAlive: true
                    }
                },
                {
                    path: 'upgrade',
                    component: () => import('../view/component/platform/upgrade'),
                    name: "upgrade",
                    meta: {
                        isAuth: true,
                        title: 'upgrade',
                        keepAlive: true
                    }
                },
                {
                    path: 'trySetting',
                    component: () => import('../view/component/setting/trySetting'),
                    meta: {
                        isAuth: true,
                        title: 'trySetting',
                        keepAlive: true
                    }
                },
                {
                    path: 'accountSetting',
                    component: () => import('../view/component/setting/accountSetting'),
                    meta: {
                        isAuth: true,
                        title: 'accountSetting',
                        keepAlive: true
                    }
                },
                {
                    path: 'smartSetting',
                    component: () => import('../view/component/setting/smartSetting'),
                    meta: {
                        isAuth: true,
                        title: 'smartSetting',
                        keepAlive: true
                    }
                },
                {
                    path: 'agencySetting',
                    component: () => import('../view/component/setting/agencySetting'),
                    meta: {
                        isAuth: true,
                        title: 'agencySetting',
                        keepAlive: true
                    }
                },
                {
                    path: 'agreementSetting',
                    component: () => import('../view/component/setting/agreementSetting'),
                    meta: {
                        isAuth: true,
                        title: 'agreementSetting',
                        keepAlive: true
                    }
                },
                {
                    path: 'reminderSetting',
                    component: () => import('../view/component/setting/reminderSetting'),
                    meta: {
                        isAuth: true,
                        title: 'reminderSetting',
                        keepAlive: true
                    }
                },
                {
                    path: 'verification',
                    component: () => import('../view/component/changeAccount/verification'),
                    meta: {
                        isAuth: true,
                        title: 'verification',
                        keepAlive: false
                    }
                },
                {
                    path: 'update',
                    component: () => import('../view/component/changeAccount/update'),
                    meta: {
                        isAuth: true,
                        title: 'update',
                        keepAlive: false
                    }
                },
                {
                    path: 'updateOk',
                    component: () => import('../view/component/changeAccount/updateOk'),
                    meta: {
                        isAuth: true,
                        title: 'updateOk',
                        keepAlive: false
                    }
                },
                {
                    path: 'softPhone',
                    component: () => import('../view/component/callout/softPhone'),
                    meta: {
                        isAuth: true,
                        title: 'softPhone',
                        keepAlive: true
                    }
                }
            ]
        },
        {
            path: '/:catchAll(.*)',
            component: () => import('../view/404'),
            meta: {
                title: "404"
            }
        }
    ],
    scrollBehavior
})
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = i18n.t(to.meta.title)
    } else {
        document.title = 'YOUSU' + i18n.t('console')
    }
    if (to.path === '/') return next()
    var token = localStorage.getItem('token')
    if (to.meta.isAuth) {
        if (!token) return next('/')
    }
    next()
})
router.onError((error) => {
    const pattern = /Loading chunk (\d)+ failed/g;
    const isChunkLoadFailed = error.message.match(pattern);
    const targetPath = router.history.pending.fullPath;
    if (isChunkLoadFailed) {
        router.replace(targetPath);
    }
})

export default router;